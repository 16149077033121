import Image from "next/image";
import Link from "next/link";

import { ReactBootstrapIcon } from "@/components/ui/LazyReactBootstrapIcons";

interface SocialIconProps {
  icon: JSX.Element;
  name: string;
  url: string;
}

const Facebook = ReactBootstrapIcon("Facebook");
const Linkedin = ReactBootstrapIcon("Linkedin");
const Youtube = ReactBootstrapIcon("Youtube");

const HomeFooter = () => {
  const socialIconClass =
    "text-white w-5 h-5 absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto";

  const socialIcons = [
    {
      icon: <Youtube className={socialIconClass} />,
      name: "youtube",
      url: "https://www.youtube.com/foliko",
    },
    {
      icon: (
        <div className={socialIconClass}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-twitter-x"
            viewBox="0 0 16 16"
          >
            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
          </svg>
        </div>
      ),
      name: "twitter",
      url: "https://twitter.com/FolikoInvest",
    },
    {
      icon: <Linkedin className={socialIconClass} />,
      name: "linkedin",
      url: "https://www.linkedin.com/company/synerai",
    },
    {
      icon: <Facebook className={socialIconClass} />,
      name: "facebook",
      url: "https://www.facebook.com/foliko",
    },
  ];

  const SocialLink = (item: SocialIconProps) => {
    return (
      <li
        className="inline-block cursor-pointer h-8"
        data-testid="social-list-item"
      >
        <Link href={item.url} passHref>
          <a
            aria-label={`foliko-${item.name}-link`}
            data-testid={`social-${item.name}`}
            className="inline-block aspect-square h-8 w-10 px-2 bg-black relative rounded-sm"
            onClick={(e) => {
              e.preventDefault();
              window.open(item.url, "_blank", "noopener,noreferrer");
            }}
          >
            {item.icon}
          </a>
        </Link>
      </li>
    );
  };

  const SocialLinks = () => (
    <ul className="inline-block">
      {socialIcons.map((item, index) => (
        <SocialLink {...item} key={index} />
      ))}
    </ul>
  );

  return (
    <div className="bg-black pt-4 pb-2 relative bottom-0 w-full z-50">
      <div className="container w-11/12 my-0 mx-auto">
        <div className="flex justify-between items-start z-20 my-0 mx-auto border-solid border-b border-neutral-400">
          <div className="">
            <div className="h-[46px] w-[140px] min-w-[110px]">
              <div className=" left-0 w-full h-full">
                <Image
                  src="/logo_white.webp"
                  alt="home-footer-logo"
                  width={70}
                  height={28}
                />
              </div>
            </div>
          </div>
          <SocialLinks />
        </div>
        <div className="mt-2">
          <p className="sm:text-[13px] text-xs text-center text-white">
            Any content provided on our website is for information purposes
            only. We do not provide investment advice, nor do we consider
            ourselves a financial institution. Seek a duly licensed professional
            for investment advice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
